/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, ReactChild } from "react";
import { useInView, IntersectionOptions } from "react-intersection-observer";
import getConfig from "next/config";

import { getOptimizeData, isBrowser } from "./browser";
import { formatLog } from "./logging";

interface TrackedElementProps {
    children: ReactChild;
    eventName: string;
    eventProps?: Object;
    enabled?: boolean;
    callback?: () => void;
}

const getAppVersion = () => {
    const { publicRuntimeConfig: { appVersion } } = getConfig();
    return appVersion ? appVersion.split(".").slice(0, 2).join(".") : "";
};

interface BaseProps {
    app_version_id: string;
    page_referrer: string;
    query_string: string;
    page: string;
    path: string;
    experiments: any[];
    tm_source: string;
    status: number;
}

type museTrackCallback = (eventName: string, eventProps: Object) => void;

/**
 * Generate the base properties for any event. We added url data, experiment
 * data. And a few other things as well.
 *
 * @export
 * @returns {BaseProps}
 */
const genBaseProps = (): BaseProps => ({
    app_version_id: getAppVersion(),
    page_referrer: document?.referrer ?? "",
    query_string: window?.document?.location?.search ?? "",
    page: window?.document?.location?.pathname ?? "",
    path: window?.document?.location?.pathname ?? "",
    experiments: [],
    tm_source: "",
    status: 200
});

/**
 * Tracks an event name and event properties. It add some base
 * properties including page data and referrer data to the event being tracked
 *
 * @param {string} eventName
 * @param {*} [eventProps={}]
 */
const museTrack = (eventName: string, eventProps: Object = {}) => {
    try {
        if (isBrowser) {
            const baseProps = genBaseProps();

            const optimizeData = getOptimizeData();
            if (optimizeData) {
                baseProps.experiments = [...baseProps.experiments, ...optimizeData];
            }

            // Capture here
            // console.info("Stub Page Event", eventName, { ...eventProps, ...baseProps });
        }
    } catch (error) {
        console.error("Event error", JSON.stringify(formatLog({ error })));
    }
};

/**
 * TrackedElement: Weapps an element in an intersection observer with tracking props.
 */
const TrackedElement = (props: TrackedElementProps & IntersectionOptions) => {
    const {
        children, eventName, eventProps = {}, enabled = true, callback, ...restProps
    } = props;

    const intersectionProps: IntersectionOptions = {
        triggerOnce: true,
        rootMargin: "0px",
        ...restProps
    };
    const { ref, inView } = useInView(intersectionProps);

    useEffect(() => {
        if (inView && enabled) {
            if (callback) {
                callback();
            }
        }
    }, [inView]);

    return (
        <div ref={ref}>
            {children}
        </div>
    );
};

/**
 * Find a specific type of ancestor of this element.
 */
const findParentElement = (el: Element, elementTypes: string[]): Element | null => {
    if (elementTypes.includes(el.tagName)) {
        return el;
    }
    if (el.parentElement && el.parentElement !== document.body) {
        return findParentElement(el.parentElement, elementTypes);
    }
    return null;
};

/**
 * Fire an event only if the element or one of its parents is an "A".
 */
const getLinkTrackingProps = (e: MouseEvent | React.MouseEvent<HTMLElement, HTMLAnchorElement>, callback?: museTrackCallback) => {
    const el: Element | HTMLAnchorElement | null = findParentElement(e.target as Element, ["A"]);
    if (!el) {
        return;
    }
    const href = (el as HTMLAnchorElement).getAttribute("href");
    const trackingProps = {
        experiments: [],
        link_href: href,
        link_name: el.textContent,
        page_referrer: document.referrer,
        path: window.location.pathname,
        placement_position: "",
        query_string: window.location.search,
        right_click: e.type === "contextmenu",
        status_code: 200,
    };
    callback("Global Link Clicked", trackingProps);
};

/**
 * Will trigger Header Link Clicked event if event target is a nav link.
 */
const trackHeaderNavLinks = (e: MouseEvent | React.MouseEvent<HTMLElement, HTMLAnchorElement>, callback?: museTrackCallback) => {
    const el: Element | HTMLAnchorElement | null = findParentElement(e.target as Element, ["A"]);
    if (!el || !el.hasAttribute("data-header-link")) {
        return;
    }
    const href = el.getAttribute("href");
    const trackingProps = {
        ...genBaseProps(),
        header_link: el.getAttribute("data-header-link"),
        link_href: href,
        link_name: el.textContent,
        placement_position: "",
        right_click: e.type === "contextmenu",
        text: el.textContent,
    };
    callback("Header Link Clicked", trackingProps);
};

const getTrackingPropsAsDataAttrs = (props: { clickEvent?: string; viewEvent?: string; eventProps?: any }) => {
    const trackingProps: { [key: string]: string } = {};
    if (props.clickEvent) {
        trackingProps.oskarClick = props.clickEvent;
    }
    if (props.viewEvent) {
        trackingProps.oskarView = props.viewEvent;
    }
    trackingProps.oskarProps = JSON.stringify(props.eventProps || {});
    return trackingProps;
};

const pushDataLayerEvent = (event: string, props: { [key: string]: any } = {}) => {
    if (isBrowser() && window.dataLayer) {
        window.dataLayer.push({ event, ...props });
    }
};

export {
    TrackedElement,
    getAppVersion,
    findParentElement,
    genBaseProps,
    getLinkTrackingProps,
    museTrack,
    trackHeaderNavLinks,
    getTrackingPropsAsDataAttrs,
    pushDataLayerEvent
};

export type {
    BaseProps,
    TrackedElementProps
};
